import React from 'react'
import { Div, BorderWhite, ContentAbout, Important, ContainerAbout } from "../style/About"
import { SpanLocation, MainTitleSection, MainTitleSectionAbout } from "../style/General"
import Navbar from './nav/Nav'
import "../style/black-background.css"
import SyncLoader from "react-spinners/SyncLoader";
import { useState, useEffect } from "react"
import FooterContact from './FooterContact'
import ChangeLanguage from './Context/ChangeLanguage'
import { useLanguage } from './Context/LanguageContext'

function About() {
    const { language } = useLanguage();
    console.log(language)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1500)
    }, [])
    const [current, setCurrent] = useState(0)
    const [autoPlay, setAutoPlay] = useState(true);
    let timeOut = null;

    return (
        <>
                    <Navbar />
                    <ChangeLanguage />

            <ContainerAbout>
                <MainTitleSectionAbout>{language === 'Fr' ? 'A propos' : 'About'}</MainTitleSectionAbout>
                <Div>
                    <BorderWhite>
                        {language === 'Fr' ?  (<ContentAbout>
                            Cléo Perrin, diplômée de l’Ecole Camondo Paris, est depuis toujours passionnée et animée par la création allant de l’échelle du design de mobilier en passant par l’architecture intérieure. <br />
                            Après avoir travaillée au sein d’agences prestigieuse tél que Liaigre, India Mahdavi et encore Wilmotte&Associés ; Cléo Perrin est dotée d’une faculté et d’une créativité s’appliquant à toute échelle de projet.
                        </ContentAbout>
                         ): 
                        <ContentAbout>
                        Cléo Perrin, a graduate of the École Camondo in Paris, has always been passionate about and driven by creation, ranging from furniture design to interior architecture.  
                        After working at prestigious agencies such as Liaigre, India Mahdavi, and Wilmotte & Associés, Cléo Perrin has developed a talent and creativity that can be applied to projects of any scale.
                      </ContentAbout>
                        }
                    </BorderWhite>
                </Div>
            </ContainerAbout>
        </>
    )
}

export default About